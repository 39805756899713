<template>
  <BasePageContent>
    <ItemDetail :uuid="uuid"></ItemDetail>
  </BasePageContent>
</template>

<script>
import ItemDetail from "@/components/ui/item/ItemDetail.vue";
export default {
  components: { ItemDetail },
  props: {
    uuid: {
      type: String,
    },
  },
};
</script>