<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      sectiontitle="ITEM DETAIL"
      :title="!Util.isEmpty(Item) ? Item.name : ''"
      :description="
        Item.track_inventory
          ? '<span class=\'mr-2 badge size12\'>Trackable item</span>' +
            (Item.returnable
              ? '<span class=\'mr-2 badge size12 w3-theme-l2\'>Returnable item</span>'
              : '<span class=\'mr-2 badge size12 w3-light-grey\'>Non-returnable item</span>')
          : ''
      "
    >
      <ul class="nk-block-tools g-1">
        <template v-if="!Util.isEmpty(Item)">
          <li v-if="Item.track_inventory" class="">
            <a
              class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
              title="Adjust Stock"
              href="#"
            >
              <em class="icon ni ni-opt"></em>
              <span>Adjust Stock</span>
            </a>
          </li>
          <li class="nk-block-tools-opt">
            <a
              href="#"
              @click.prevent="EditItem"
              class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
            >
              <em class="icon ni ni-edit"></em
            ></a>
          </li>

          <li>
            <div class="drodown">
              <a
                href="#"
                class="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                data-toggle="dropdown"
              >
                <em class="icon ni ni-more-h"></em
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <ul class="link-list-opt no-bdr">
                  <li>
                    <a title="Duplicate" href="#">
                      <em class="dd-indc icon ni ni-copy"></em>
                      <span>Duplicate Item</span>
                    </a>
                  </li>
                  <li>
                    <a title="Disable" href="#">
                      <em class="dd-indc icon ni ni-copy"></em>
                      <span>Disable Item</span>
                    </a>
                  </li>
                  <li>
                    <a
                      title="Delete"
                      class="w3-text-red w3-hover-text-black"
                      href="#"
                    >
                      <em class="dd-indc icon ni ni-trash"></em>
                      <span> Delete Item</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </template>
        <li v-if="View === 'Overview'" class="nk-block-tools-opt">
          <a href="#" @click.prevent="$router.go(-1)"
            ><em class="icon ni ni-cross size36"></em
          ></a>
        </li>
        <li v-else class="nk-block-tools-opt">
          <a href="#" @click.prevent="View = 'Overview'"
            ><em class="icon ni ni-cross size36"></em
          ></a>
        </li>
      </ul>
    </BasePageHeader>
    <div class="card-inner card-inner-md">
      <BaseIndicator
        text="Fetching item..."
        class="my-4"
        v-if="Util.fetching('getItem')"
        :size="50"
      />
      <template v-else>
        <template v-if="!Util.isEmpty(Item)">
          <div class="d-flex justify-content-between">
            <ul class="nk-block-tools g-1">
              <li class="nk-block-tools-opt">
                <a
                  href="#"
                  @click.prevent="View = 'Overview'"
                  class="btn btn-white btn-dim w3-hover-light-grey"
                  :class="View === 'Overview' ? 'w3-light-grey' : ''"
                >
                  <em class="icon ni ni-dashboard"></em>
                  <span class="d-none d-md-inline">Overview</span></a
                >
              </li>
              <li class="nk-block-tools-opt">
                <a
                  href="#"
                  @click.prevent="View = 'Transactions'"
                  class="btn btn-white btn-dim w3-hover-light-grey"
                  :class="View === 'Transactions' ? 'w3-light-grey' : ''"
                >
                  <em class="icon ni ni-tranx"></em>
                  <span class="d-none d-md-inline">Transactions</span></a
                >
              </li>
              <li class="nk-block-tools-opt">
                <a
                  href="#"
                  @click.prevent="View = 'History'"
                  class="btn btn-white btn-dim w3-hover-light-grey"
                  :class="View === 'History' ? 'w3-light-grey' : ''"
                >
                  <em class="icon ni ni-history"></em>
                  <span class="d-none d-md-inline"> History Item</span></a
                >
              </li>
              <li class="nk-block-tools-opt">
                <a
                  href="#"
                  @click.prevent="EditItem"
                  class="btn btn-white btn-dim w3-hover-light-grey"
                >
                  <em class="icon ni ni-edit"></em>
                  <span class="d-none d-md-inline"> Edit Item</span></a
                >
              </li>
            </ul>
          </div>
          <div
            class="card-inner card-inner-md w3-animate-opacity"
            v-show="View === 'Overview'"
          >
            <div class="row">
              <div class="col-md-8">
                <div
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13">Type</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    {{ Item.type === "item" ? "Item" : "Service" }}
                  </div>
                </div>
                <div
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13" for="name">Name</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    {{ Item.name }}
                  </div>
                </div>
                <div
                  v-if="Item.unit"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13" for="unit">Unit</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    {{ Item.unit }}
                  </div>
                </div>
                <div
                  v-if="Item.type === 'item'"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">Returnable</div>
                  <div class="col-md-8 mb-3">
                    {{ Item.returnable ? "Yes" : "No" }}
                  </div>
                </div>
                <div
                  v-if="Item.description"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13" for="description"
                      >Item Description</label
                    >
                  </div>
                  <div class="col-md-8 mb-3" v-html="Item.description"></div>
                </div>
                <div
                  v-if="Item.length || Item.width || Item.height"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13 mb-0" for="length">Dimension</label>
                    <div class="hint form-text">(L X W X H)</div>
                  </div>
                  <div class="col-md-8 mb-3">
                    <span v-if="Item.length">{{ Item.length }}</span>
                    <span v-if="Item.width || Item.height"> x </span>
                    <span v-if="Item.width">{{ Item.width }}</span>
                    <span v-if="Item.height"> x </span>
                    <span v-if="Item.height">{{ Item.height }}</span>
                    <span v-if="Item.length || Item.width || Item.height">{{
                      Item.dimension_unit
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="Item.weight_measure === 'density' && Item.density"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13">Density</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    <span v-if="Item.density"
                      >{{ Item.density }}{{ Item.density_unit }}</span
                    >
                  </div>
                </div>
                <div
                  v-if="Item.weight_measure === 'weight' && Item.weight"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13">Weight</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    <span v-if="Item.weight"
                      >{{ Item.weight }}{{ Item.weight_unit }}</span
                    >
                  </div>
                </div>
                <div
                  v-if="Item.sku"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13">SKU</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    {{ Item.sku }}
                  </div>
                </div>
                <div
                  v-if="Item.upc"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13">UPC</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    {{ Item.upc }}
                  </div>
                </div>
                <div
                  v-if="Item.mpn"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13" for="mpn">MPN</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    {{ Item.mpn }}
                  </div>
                </div>
                <div
                  v-if="Item.ean"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13" for="ean">EAN</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    {{ Item.ean }}
                  </div>
                </div>
                <template v-if="Item.is_sale">
                  <h4
                    v-if="Item.is_sale"
                    class="pt-2 mb-3 letter-spacing-2 size14"
                  >
                    SALE INFORMATION
                  </h4>
                  <div
                    v-if="Item.sale_price"
                    class="
                      row
                      g-2
                      w3-border-bottom w3-border-light-grey
                      mb-2
                      pb-2
                      pt-2
                      w3-animate-opacity
                    "
                  >
                    <div class="col-md-4">
                      <label class="size13">Selling price</label>
                    </div>
                    <div class="col-md-8 mb-3">
                      <span v-html="Util.Currency(Item.sale_price)"></span>
                    </div>
                  </div>
                  <div
                    v-if="Item.sale_tax.length"
                    class="
                      row
                      g-2
                      w3-border-bottom w3-border-light-grey
                      mb-2
                      pb-2
                      pt-2
                      w3-animate-opacity
                    "
                  >
                    <div class="col-md-4">
                      <label class="size13">Sale Tax</label>
                    </div>
                    <div class="col-md-8 mb-3">
                      <template v-for="tax in Item.sale_tax" :key="tax">
                        <div class="pb-1">
                          {{ SalesTax[tax].tax_rate }}%
                          {{ SalesTax[tax].tax_name }}
                          <span v-if="SalesTax[tax].tax_abbr"
                            >({{ SalesTax[tax].tax_abbr }})</span
                          >
                          <span
                            class="ml-2"
                            title="Tax ID is displayed on invoices"
                            v-if="
                              SalesTax[tax].tax_id && SalesTax[tax].show_tax_id
                            "
                            ><i class="fas fa-eye size18"></i
                          ></span>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
                <template v-if="Item.is_purchase">
                  <h4
                    v-if="Item.is_purchase"
                    class="pt-2 mb-3 letter-spacing-2 size14"
                  >
                    PURCHASE INFORMATION
                  </h4>
                  <div
                    v-if="Item.cost_price"
                    class="
                      row
                      g-2
                      w3-border-bottom w3-border-light-grey
                      mb-2
                      pb-2
                      pt-2
                      w3-animate-opacity
                    "
                  >
                    <div class="col-md-4">
                      <label class="size13">Cost price</label>
                    </div>
                    <div class="col-md-8 mb-3">
                      <span v-html="Util.Currency(Item.cost_price)"></span>
                    </div>
                  </div>
                </template>
                <div
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-4">
                    <label class="size13">Inventory tracking</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    {{ Item.track_inventory ? "Yes" : "No" }}
                  </div>
                </div>
                <template v-if="Item.track_inventory">
                  <div
                    v-if="Item.opening_stock"
                    class="
                      row
                      g-2
                      w3-border-bottom w3-border-light-grey
                      mb-2
                      pb-2
                      pt-2
                      w3-animate-opacity
                    "
                  >
                    <div class="col-md-4">
                      <label class="size13">Opening Stock</label>
                    </div>
                    <div class="col-md-8 mb-3">
                      {{ Item.opening_stock }}
                    </div>
                  </div>
                  <div
                    v-if="Item.reorder_point"
                    class="
                      row
                      g-2
                      w3-border-bottom w3-border-light-grey
                      mb-2
                      pb-2
                      pt-2
                      w3-animate-opacity
                    "
                  >
                    <div class="col-md-4">
                      <label class="size13">Low Stock Point</label>
                    </div>
                    <div class="col-md-8 mb-3">
                      {{ Item.reorder_point }}
                    </div>
                  </div>
                  <div
                    v-if="Item.opening_stock_price"
                    class="
                      row
                      g-2
                      w3-border-bottom w3-border-light-grey
                      mb-2
                      pb-2
                      pt-2
                      w3-animate-opacity
                    "
                  >
                    <div class="col-md-4">
                      <label class="size13">Opening Stock Cost Price</label>
                    </div>
                    <div class="col-md-8 mb-3">
                      <span v-html="Util.Currency(Item.opening_stock_price)">
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="Item.vendor"
                    class="
                      row
                      g-2
                      w3-border-bottom w3-border-light-grey
                      mb-2
                      pb-2
                      pt-2
                      w3-animate-opacity
                    "
                  >
                    <div class="col-md-4">
                      <label class="size13" for="vendor"
                        >Preferred Item Vendor</label
                      >
                    </div>
                    <div class="col-md-8 mb-3">
                      {{ Item.vendor }}
                    </div>
                  </div>
                </template>
                <div
                  v-if="Item.internal_note"
                  class="
                    row
                    g-2
                    w3-border-bottom w3-border-light-grey
                    mb-2
                    pb-2
                    pt-2
                    w3-animate-opacity
                  "
                >
                  <div class="col-md-3">
                    <label class="size13" for="internal_note"
                      >Internal note</label
                    >
                  </div>
                  <div class="col-md-8 mb-3" v-html="Item.internal_note"></div>
                </div>
              </div>
              <div class="col-md-4">
                <template v-if="Item.item_photos_url.length">
                  <div class="row g-1 mb-3 w3-animate-opacity">
                    <div
                      v-for="(photo, index) in Item.item_photos_url"
                      :key="index"
                      class="col-6 col-sm-4"
                    >
                      <div
                        class="w3-border w3-border-light-grey background-image"
                        style="aspect-ratio: 1 / 1"
                        :style="{ backgroundImage: 'url(' + photo.url + ')' }"
                      ></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div
            class="card-inner card-inner-md w3-animate-opacity"
            v-show="View === 'Transactions'"
          >
            <p>Transactions</p>
          </div>
          <div
            class="card-inner card-inner-md w3-animate-opacity"
            v-show="View === 'History'"
          >
            <p>History</p>
          </div>
        </template>
        <div v-else class="p-5 w3-center">
          <img src="/images/item-not-found.png" class="mb-4" />
          <h4>Item not found</h4>
        </div>
      </template>
    </div>
  </BasePageContent>
</template>

<script>
import { ref } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Util } from "@/helpers/utilities";
export default {
  components: {},
  props: {
    uuid: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const activeIitem = store.state.items.item;
    if (Util.isEmpty(activeIitem) || props.uuid !== activeIitem.uuid) {
      store.dispatch("items/unsetItem");
      store.dispatch("items/getItem", props.uuid);
    }
    const Item = computed(() => {
      const _item = store.state.items.item;
      return {
        ..._item,
      };
    });
    const View = ref("Overview");
    const router = useRouter();
    const SalesTax = computed(() => {
      const _taxes = store.getters["taxes/taxes"];
      const Taxes = {};
      _taxes.forEach((tax) => {
        Taxes[tax.uuid] = tax;
      });
      return Taxes;
    });
    const EditItem = () => {
      router.replace({
        name: "item-edit",
        params: { uuid: props.uuid },
      });
    };
    return {
      store,
      Item,
      Util,
      View,
      EditItem,
      SalesTax,
    };
  },
};
</script>